import React from "react";

import FetchHelper from "../utils/FetchHelper";
import PreLoad from "./components/PreLoad";

const INDEED_LOGO = require('../assets/images/small_image_12-01-2023_10-37-08.png')
const AMPLY_LOGO = require('../assets/images/RS01017_Amply_Rebrand_Logo_RGB_AW_Amply_Logo_RGB_Grey.png')

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      url: '',
      isLoading: false,
    }
  }

  _isValid(url){
    return true
  }

  _submit(){
    let {
      url
    } = this.state

    this.setState({isLoading: true})

    if(!this._isValid(url)){
      this.setState({isLoading: false})
      return
    }

    let data = {
      website_url: url
    }

    FetchHelper.post(window.Api.Sites, data, false, false).then(response => {
      window.location = response.one_time_login_url
    }).catch(error => {
      this.setState({isLoading: false, error: error.message})
    })
  }

  render() {
    let {
      url,
      error,
      isLoading
    } = this.state

    if(isLoading){
      return (
        <PreLoad/>
      )
    }

    return (
      <>
        <div id="nav-container" className="nav-container">
          <div>

            <div className="bar bar--sm visible-xs">
              <div className="container">
                <div className="row">
                  <div className="col-3 col-md-2">
                    <a href="https://indeed.com">
                      <img className="logo logo-dark" alt="logo" src={AMPLY_LOGO} />
                      <img className="logo logo-light" alt="logo" src={AMPLY_LOGO} />
                    </a>
                  </div>
                  <div className="col-9 col-md-10 text-right">
                    <a href="#" className="hamburger-toggle" data-toggle-class="#menu1;hidden-xs hidden-sm">
                      <i className="icon icon--sm stack-interface stack-menu"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <nav id="menu1" className={`bar bar-1 hidden-xs pos-fixed` } style={{boxShadow: 'unset'}}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 text-center text-left-sm hidden-xs order-md-2">
                    <div className="bar__module">
                      <a href="https://indeed.com">
                        <img className="logo logo-dark" alt="logo" src={AMPLY_LOGO} style={{maxHeight: '105px'}} />
                        <img className="logo logo-light" alt="logo" src={AMPLY_LOGO} style={{maxHeight: '105px'}} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>

          </div>
        </div>

        <section className={" text-center job-banner cover height-100 "} style={{zIndex: 0}}>
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="text-center col-lg-12 pb-xl-5">
                <img className="logo logo-dark" alt="logo" src={INDEED_LOGO} style={{height: 150}} />
              </div>
              <div className="col-lg-12">
                <h1 id="name" className="type--bold">Corporate Website Generator</h1>
                <div className="boxed boxed--lg bg--white text-left" id="search">
                  <form className="form--horizontal search">
                    <div className="col-lg-9" style={{padding: 0, zIndex: 3}}>
                      <div className="equal">
                        <div id="search-jobkeyword" className="col-lg-12">
                          <input
                            type="text"
                            className="form-custom"
                            value={url}
                            placeholder={`Enter Glassdoor URL...`}
                            onChange={e => {
                              let url = e.target.value;
                              this.setState({url})
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <button
                        type="submit"
                        className="btn btn--primary type--bold"
                        style={{backgroundColor: '#252525', borderColor: '#252525'}}
                        onClick={e => {
                          e.preventDefault()
                          this._submit()
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </form>
                  {
                    error &&
                    <div className="text-center">
                      <p className="text-danger">{error}</p>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

}
