import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                      = window.Api.User.Base + "/login"
window.Api.User.Logout                     = window.Api.User.Base + "/logout"
window.Api.User.Info                       = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword       = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword              = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken               = window.Api.User.Base + "/refresh-token"

window.Api.Sites                           = window.Api.Base + "/sites"
